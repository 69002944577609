<template>
  <v-container fluid>
    <h3>Order Reports</h3>

    <v-row justify="center">
      <v-btn
        fab
        color="cyan"
        absolute
        top
        class="mt-6"
        small
        right
        @click.native.stop="reload"
      >
        <v-icon color="white">refresh</v-icon>
      </v-btn>
    </v-row>
    <v-sheet elevation="1">
      <v-row align="center">
        <v-col cols="2" sm="3" md="2" lg="1"> Total # {{ totalOrders }} </v-col>
        <v-col cols="4" md="2">
          <v-select
            label="Top Producer"
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            v-model="rowCount"
          ></v-select>
        </v-col>
        <v-col cols="4" sm="3" md="2" class="caption">
          <span v-if="pastDay === 1">Just today</span>
          <span v-if="pastDay === 7">Current Week</span>
          <span v-if="pastDay === 30">Current Month</span>
          <v-select
            label="Past Days"
            :items="days"
            v-model="pastDay"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-if="allProducers && allProducers.length > 0"
        >
          <v-select
            label="Only"
            :items="allProducers"
            multiple
            v-model="filtered"
          ></v-select>
        </v-col>
      </v-row>
    </v-sheet>
    <v-row align="center">
      <v-col cols="12" md="6">
        <svg :width="width" :height="height" class="svgPie" />
      </v-col>

      <v-col cols="12" md="6" v-if="selectedProducer">
        <v-simple-table fixed-header dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Seller</th>
                <th class="text-left">Buyer</th>
                <th class="text-left">Delivery</th>
                <th class="text-left">Status</th>
                <th class="text-left">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders[selectedProducer]" :key="order.id">
                <td>
                  <span class="body-1">
                    <router-link
                      :to="
                        '/order/view/v2.0/' +
                          order.sellerUserId +
                          '/' +
                          order.id
                      "
                      >{{ order.id.substring(0, 4) }}</router-link
                    >
                  </span>
                </td>
                <td>
                  <span class="body-1">{{ order.seller.businessName }} </span>
                </td>
                <td>
                  <span class="body-1">{{ order.buyer.name }} </span>
                </td>
                <td>
                  <span class="body-1">{{ order.deliveryMethod }} </span>
                </td>
                <td>
                  <span class="body-1">{{ order.status }} </span>
                </td>
                <td>
                  <span class="body-1"
                    >{{ order.payment.all_fees.total | currency }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-btn icon to="/report" class="ml-2">
      <v-icon color="info">arrow_back</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
// import moment from 'moment'
import { mapGetters } from 'vuex'
import * as d3 from 'd3'
import _ from 'lodash'

export default {
  data() {
    return {
      orders: [],
      chart: null,
      rowCount: 5,
      width: 350,
      height: 350,
      margin: 40,
      totalOrders: 0,
      pastDay: 1,
      selectedProducer: null,
      filtered: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    }),
    days() {
      return [...Array(31).keys()]
    },
    allProducers() {
      return Object.keys(this.orders)
    }
  },
  async mounted() {
    await this.reload()
  },
  watch: {
    rowCount() {
      this.renderChart()
    },
    pastDay() {
      this.reload()
    },
    filtered() {
      this.renderChart()
    }
  },
  methods: {
    async reload() {
      await this.loadOrders()
      await this.renderChart()
      //await this.renderBarChart()
    },
    async renderChart() {
      if (!this.orders) {
        console.warn(`orders is empty`)
        return
      }

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      var radius = Math.min(this.width, this.height) / 2 - this.margin

      d3.select('.svgPie')
        .selectAll('*')
        .remove()

      var svg = d3
        .select('.svgPie')
        .append('g')
        .attr(
          'transform',
          'translate(' + this.width / 2 + ',' + this.height / 2 + ')'
        )

      // Create dummy data
      // var data = { a: 9, b: 20, c: 30, d: 8, e: 12 }
      let data = []
      Object.keys(this.orders).forEach(key => {
        data[key] = this.orders[key].length
      })
      if (this.filtered && this.filtered.length > 0) {
        Object.keys(data).forEach(key => {
          if (!this.filtered.includes(key)) {
            delete data[key]
          }
        })
      }

      // set the color scale
      var color = d3
        .scaleOrdinal()
        .domain(data)
        .range(d3.schemeSet2)

      // Compute the position of each group on the pie:
      var pie = d3.pie().value(function(d) {
        return d.value
      })

      let entries = _.orderBy(d3.entries(data), ['value'], ['desc'])
      entries = _.slice(entries, 0, this.rowCount)
      this.totalOrders = entries.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      )

      var data_ready = pie(entries)
      // Now I know that group A goes from 0 degrees to x degrees and so on.

      // shape helper to build arcs:
      var arcGenerator = d3
        .arc()
        .innerRadius(0)
        .outerRadius(radius)

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      let vm = this
      svg
        .selectAll('mySlices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', arcGenerator)
        .attr('fill', function(d) {
          return color(d.data.key)
        })
        .attr('stroke', 'black')
        .style('stroke-width', '2px')
        .style('opacity', 0.7)

        .on('click', function(d) {
          vm.selectedProducer = d.data.key
        })

      // Now add the annotation. Use the centroid method to get the best coordinates
      svg
        .selectAll('mySlices')
        .data(data_ready)
        .enter()
        .append('text')
        .text(function(d) {
          return d.data.value + ' ' + d.data.key
        })
        .attr('transform', function(d) {
          return 'translate(' + arcGenerator.centroid(d) + ')'
        })
        .style('text-anchor', 'middle')
        .style('font-size', 17)
    },
    async renderBarChart() {},
    async loadOrders() {
      if (this.isAdmin) {
        if (!this.$route.params.uid) {
          await this.$store
            .dispatch('findOrders', { recent: true, pastDay: this.pastDay })
            .then(data => {
              const list = _.chain(data)
                .groupBy('seller.businessName')
                //.map((currentItem, name) => {
                //  return { name, value: currentItem.length }
                //})
                .value()

              this.orders = list
            })
        }
      }
    }
  }
}
</script>
